var axios = require('axios');

export default class MainService {

    constructor() {
        var token = JSON.parse(localStorage.getItem('token'));
        axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE;
        if (token) {
            axios.defaults.headers.common = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        } else {
            axios.defaults.headers.common = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    getToken() {
        return axios.defaults.headers.common;
    }

    login(item) {
        return axios.post('api/login', item);
    }

    uploadFile(item) {
        return axios.post('api/uploadFile', item);
    }

    loginToken365(item) {
        return axios.post('api/loginToken365', item);
    }

    indexPersona() {
        return axios.defaults.baseURL + 'api/Persona/index';
    }

    Usuarios() {
        return axios.defaults.baseURL + 'api/Persona/Usuarios';
    }

    morosidad(item) {
        return axios.post('api/Persona/morosidad', item);
    }

    // Usuarios() {
    //     return axios.get('api/Persona/Usuarios');
    // }

    showPersona(id) {
        return axios.get('api/Persona/show?id=' + id);
    }

    listPersona() {
        return axios.get('api/Persona/list');
    }

    storePersona(item) {
        return axios.post('api/Persona/store', item);
    }

    destroyPersona(item) {
        return axios.post('api/Persona/destroy', item);
    }

    select2Persona(q) {
        return axios.get('api/Persona/select2?q=' + q);
    }

    changePassword(item) {
        return axios.post('api/Persona/changePassword', item);
    }

    storeLogin(item) {
        return axios.post('api/Persona/storeLogin', item);
    }


    /* ----------------------------------------------------- Unidad Academica*/
    showUnidadAcademica(id) {
        return axios.get('api/UnidadAcademica/show?id=' + id);
    }

    indexUnidadAcademica() {
        return axios.defaults.baseURL + 'api/UnidadAcademica/index';
    }

    listUnidadAcademica() {
        return axios.get('api/UnidadAcademica/list');
    }

    storeUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/store', item);
    }

    destroyUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/destroy', item);
    }

    showRol(id) {
        return axios.get('api/Rol/show?id=' + id);
    }

    indexRol() {
        return axios.defaults.baseURL + 'api/Rol/index';
    }

    listRol() {
        return axios.get('api/Rol/list');
    }

    storeRol(item) {
        return axios.post('api/Rol/store', item);
    }

    destroyRol(item) {
        return axios.post('api/Rol/destroy', item);
    }
    // -----------------------------------TipoLibro
    showTipoLibro(id) {
        return axios.get('api/TipoLibro/show?id=' + id);
    }

    indexTipoLibro() {
        return axios.defaults.baseURL + 'api/TipoLibro/index';
    }

    listTipoLibro() {
        return axios.get('api/TipoLibro/list');
    }

    storeTipoLibro(item) {
        return axios.post('api/TipoLibro/store', item);
    }

    destroyTipoLibro(item) {
        return axios.post('api/TipoLibro/destroy', item);
    }
    // -----------------------------------Categoria
    showCategoria(id) {
        return axios.get('api/Categoria/show?id=' + id);
    }

    indexCategoria() {
        return axios.defaults.baseURL + 'api/Categoria/index';
    }

    listCategoria() {
        return axios.get('api/Categoria/list');
    }

    storeCategoria(item) {
        return axios.post('api/Categoria/store', item);
    }

    destroyCategoria(item) {
        return axios.post('api/Categoria/destroy', item);
    }
    // -----------------------------------Origen
    showOrigen(id) {
        return axios.get('api/Origen/show?id=' + id);
    }

    indexOrigen() {
        return axios.defaults.baseURL + 'api/Origen/index';
    }

    listOrigen() {
        return axios.get('api/Origen/list');
    }

    storeOrigen(item) {
        return axios.post('api/Origen/store', item);
    }

    destroyOrigen(item) {
        return axios.post('api/Origen/destroy', item);
    }
    // -----------------------------------Editorial
    showEditorial(id) {
        return axios.get('api/Editorial/show?id=' + id);
    }

    indexEditorial() {
        return axios.defaults.baseURL + 'api/Editorial/index';
    }

    listEditorial() {
        return axios.get('api/Editorial/list');
    }

    storeEditorial(item) {
        return axios.post('api/Editorial/store', item);
    }

    destroyEditorial(item) {
        return axios.post('api/Editorial/destroy', item);
    }
    // -----------------------------------Autor
    showAutor(id) {
        return axios.get('api/Autor/show?id=' + id);
    }

    indexAutor() {
        return axios.defaults.baseURL + 'api/Autor/index';
    }

    listAutor() {
        return axios.get('api/Autor/list');
    }

    storeAutor(item) {
        return axios.post('api/Autor/store', item);
    }

    destroyAutor(item) {
        return axios.post('api/Autor/destroy', item);
    }

    select2Autor(q) {
        return axios.get('api/Autor/select2?q=' + q);
    }

    // -----------------------------------Estado
    showEstado(id) {
        return axios.get('api/Estado/show?id=' + id);
    }

    indexEstado() {
        return axios.defaults.baseURL + 'api/Estado/index';
    }

    listEstado() {
        return axios.get('api/Estado/list');
    }

    storeEstado(item) {
        return axios.post('api/Estado/store', item);
    }

    destroyEstado(item) {
        return axios.post('api/Estado/destroy', item);
    }
    // -----------------------------------Libro
    showLibro(id) {
        return axios.get('api/Libro/show?id=' + id);
    }

    indexLibro() {
        return axios.defaults.baseURL + 'api/Libro/index';
    }

    listLibro(item) {
        return axios.get('api/Libro/list', {params: item});
    }

    /* listPage(page) {
        return axios.get('api/Libro/listPage?id=' + page);
    } */

    storeLibro(item) {
        return axios.post('api/Libro/store', item);
    }

    destroyLibro(item) {
        return axios.post('api/Libro/destroy', item);
    }
    select2Libro(q) {
        return axios.get('api/Libro/select2?q=' + q);
    }
    searchTitle(q) {
        return axios.get('api/Libro/searchTitle?q=' + q);
    }
    searchAutor(qa) {
        return axios.get('api/Libro/searchAutor?q=' + qa);
    }
    // -----------------------------------Reparticion
    showReparticion(id) {
        return axios.get('api/Reparticion/show?id=' + id);
    }

    indexReparticion() {
        return axios.defaults.baseURL + 'api/Reparticion/index';
    }

    listReparticion(item) {
        return axios.get('api/Reparticion/list', {params: item});
    }

    storeReparticion(item) {
        return axios.post('api/Reparticion/store', item);
    }

    destroyReparticion(item) {
        return axios.post('api/Reparticion/destroy', item);
    }
    // -----------------------------------Reserva
    showReserva(id) {
        return axios.get('api/Reserva/show?id=' + id);
    }

    indexReserva() {
        return axios.defaults.baseURL + 'api/Reserva/index';
    }

    listReserva() {
        return axios.get('api/Reserva/list');
    }

    storeReserva(item) {
        return axios.post('api/Reserva/store', item);
    }

    storePrestamo(item) {
        return axios.post('api/Reserva/storePrestamo', item);
    }


    destroyReserva(item) {
        return axios.post('api/Reserva/destroy', item);
    }

    select2Reserva(q) {
        return axios.get('api/Reserva/select2?q=' + q);
    }

    NotificacionOperador(item) {
        return axios.post('api/Reserva/NotificacionOperador', item);
    }

    ConfirmacionEstudiante(item) {
        return axios.post('api/Reserva/ConfirmacionEstudiante', item);
    }

    confirmarReserva(item) {
        return axios.post('api/Reserva/confirmarReserva', item);
    }

    generarReporte(item) {
        return axios.post('api/Reserva/generarReporte', item);
    }

    reporteEstudiante(item) {
        return axios.post('api/Reserva/reporteEstudiante', item);
    }

    reporteCarrera(item) {
        return axios.post('api/Reserva/reporteCarrera', item);
    }

    reporteGeneralPDFRango(item) {
        return axios.post('api/Reserva/reporteGeneralPDFRango', item);
    }

    cambioEstado(item) {
        return axios.get('api/Reserva/cambioEstado', item);
    }

    estadisticas(item) {
        return axios.get('api/Reserva/estadisticas', {params:item});
    }

    // -----------------------------------Libro Autor
    showLibroAutor(id) {
        return axios.get('api/LibroAutor/show?id=' + id);
    }

    indexLibroAutor() {
        return axios.defaults.baseURL + 'api/LibroAutor/index';
    }

    listLibroAutor() {
        return axios.get('api/LibroAutor/list');
    }

    storeLibroAutor(item) {
        return axios.post('api/LibroAutor/store', item);
    }

    destroyLibroAutor(item) {
        return axios.post('api/LibroAutor/destroy', item);
    }

    select2LibroAutorLibro(ql) {
        return axios.get('api/LibroAutor/select2?q=' + ql);
    }

    select2LibroAutorAutor(qa) {
        return axios.get('api/LibroAutor/select2?q=' + qa);
    }
    // -----------------------------------Clase
    showClase(id) {
        return axios.get('api/Clase/show?id=' + id);
    }

    indexClase() {
        return axios.defaults.baseURL + 'api/Clase/index';
    }

    listClase() {
        return axios.get('api/Clase/list');
    }

    storeClase(item) {
        return axios.post('api/Clase/store', item);
    }

    destroyClase(item) {
        return axios.post('api/Clase/destroy', item);
    }
    // -----------------------------------Biblioteca Virtual
    showBibliotecaVirtual(id) {
        return axios.get('api/BibliotecaVirtual/show?id=' + id);
    }

    indexBibliotecaVirtual() {
        return axios.defaults.baseURL + 'api/BibliotecaVirtual/index';
    }

    listBibliotecaVirtual() {
        return axios.get('api/BibliotecaVirtual/list');
    }

    storeBibliotecaVirtual(item) {
        return axios.post('api/BibliotecaVirtual/store', item);
    }

    destroyBibliotecaVirtual(item) {
        return axios.post('api/BibliotecaVirtual/destroy', item);
    }
    // -----------------------------------Diccionario Militar
    showDiccionarioMilitar(id) {
        return axios.get('api/DiccionarioMilitar/show?id=' + id);
    }

    indexDiccionarioMilitar() {
        return axios.defaults.baseURL + 'api/DiccionarioMilitar/index';
    }

    listDiccionarioMilitar(item) {
        return axios.get('api/DiccionarioMilitar/list',{params: item});
    }

    storeDiccionarioMilitar(item) {
        return axios.post('api/DiccionarioMilitar/store', item);
    }

    destroyDiccionarioMilitar(item) {
        return axios.post('api/DiccionarioMilitar/destroy', item);
    }
    select2DiccionarioMilitar(q) {
        return axios.get('api/DiccionarioMilitar/select2?q=' + q);
    }

    // ----------------------------------- Nivel Académico
    showNivelAcademico(id) {
        return axios.get('api/NivelAcademico/show?id=' + id);
    }

    indexNivelAcademico() {
        return axios.defaults.baseURL + 'api/NivelAcademico/index';
    }

    listNivelAcademico(item) {
        return axios.get('api/NivelAcademico/list',{params: item});
    }

    storeNivelAcademico(item) {
        return axios.post('api/NivelAcademico/store', item);
    }

    destroyNivelAcademico(item) {
        return axios.post('api/NivelAcademico/destroy', item);
    }
    select2NivelAcademico(q) {
        return axios.get('api/NivelAcademico/select2?q=' + q);
    }

    // ----------------------------------- Especialidad
    showEspecialidad(id) {
        return axios.get('api/NivelAcademico/show?id=' + id);
    }

    indexEspecialidad() {
        return axios.defaults.baseURL + 'api/Especialidad/index';
    }

    listEspecialidad(item) {
        return axios.get('api/Especialidad/list',{params: item});
    }

    storeEspecialidad(item) {
        return axios.post('api/Especialidad/store', item);
    }

    destroyEspecialidad(item) {
        return axios.post('api/Especialidad/destroy', item);
    }
   
    

}