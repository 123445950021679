<template>
  <!-- Image and text  bg-transparent-->

<div>
  
    <div>
        <b-navbar toggleable="lg" type="dark"  class="fixed-top" style="background-color:#2D5BB7 ;">
            <b-navbar-brand class="ml-5">
                 <router-link
                    class="waves-effect"
                    to="Inscribete"
                    active-class="active"
                >
                    <img
                    src="/assets//images/emi_logo.png"
                    width="200"
                    height="100"
                    alt=""
                    />
                </router-link>
        </b-navbar-brand>
            

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>

            <!-- Right aligned nav items #70a2ba  -->
            <b-navbar-nav class="ml-auto">
                 <b-nav-item>
                     <router-link
                        class="waves-effect text-white"
                        to="Inscribete"
                        active-class="active"
                        >
                        INICIO
                    </router-link>
            </b-nav-item>
               
                <!-- <b-nav-item v-if="auth.id == null" @click="abrirModalRegistro" >REGISTRARSE</b-nav-item> -->
                <!-- <b-nav-item v-if="auth.id == null" @click="">LOGIN</b-nav-item> -->
                <b-nav-item>
                     <router-link
                        class="waves-effect text-white"
                        to="Login"
                        dark
                        active-class="active"
                        >
                        LOGIN
                    </router-link>
                 </b-nav-item>

                
                <b-nav-item-dropdown class="btn btn-outline-primary" text="OPCIONES DE BÚSQUEDA" right>
                    
                   <b-dropdown-item>
                        <router-link
                            class="btn btn-default"
                            to="Buscador"
                            active-class="active"
                        >
                            <v-icon>mdi-airplay</v-icon>
                            Búsqueda de Libros
                        </router-link>
                    </b-dropdown-item>

                    <b-dropdown-item>
                        <router-link
                            class="btn btn-default"
                            to="BuscadorBibliotecaVirtual"
                            active-class="active"
                        >
                            <v-icon>mdi-airplay</v-icon>
                            Bibliotecas Virtuales
                        </router-link>
                    </b-dropdown-item>
                    
                   <!-- <b-dropdown-item>
                        <a
                            href="https://repositoriogrado.servicios.emi.edu.bo/#/"
                            target="_blank"
                            active-class="active"
                            >
                            <v-icon>mdi-note-multiple-outline</v-icon>
                            Trabajos de Grado
                        </a>
                    </b-dropdown-item> -->

                    <!-- <b-dropdown-item>
                        <router-link
                            class="btn btn-default"
                            href="https://repositorioposgrado.servicios.emi.edu.bo/#/"
                            active-class="active"
                        >
                            <v-icon>mdi-note-multiple</v-icon>
                            Trabajos de Posgrado
                        </router-link>
                    </b-dropdown-item> -->

                   <!--  <b-dropdown-item>
                        <router-link
                            class="btn btn-default"
                            to="Buscador"
                            active-class="active"
                        >
                            <v-icon>mdi-book-open</v-icon>
                            Investigaciones
                        </router-link>
                    </b-dropdown-item> -->

                    <b-dropdown-item>
                        <router-link
                            class="btn btn-default"
                            to="BuscadorDiccionarioMilitar"
                            active-class="active"
                        >
                            <v-icon> mdi-wan </v-icon>
                            Diccionario Militar
                        </router-link>
                    </b-dropdown-item>

                </b-nav-item-dropdown>

                <b-nav-item-dropdown class="btn btn-outline-warning" right v-if="auth.id != null">
                    
                <!-- Using 'button-content' slot -->
                <template #button-content>
                    <em>{{auth.Persona}}</em>
                </template>
                <b-dropdown-item >
                    <router-link
                        class="waves-effect text-black"
                        to="MiPerfil"
                        active-class="active"
                        >
                        Ver perfil
                    </router-link>
                </b-dropdown-item>
                <b-dropdown-item @click="cerrarSesion">Cerrar Sesion</b-dropdown-item>
                </b-nav-item-dropdown>

            </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>


</div>
    
  
  <!-- End Header -->
</template>

<script>
import MainService from "@/services/MainService.js";

export default {
    name: "MenuExterno",
  data() {
    
    return {
        ss : new MainService(),
        MenuExterno: {},
        errorBag: {},
        nuevaPersona:{},
        loginCredenciales: {},
        auth: {},
        unidadAcademicas:[],
        mostrarVerPrograma:false,
    };
  },

  
  mounted() {
    this.auth = JSON.parse(localStorage.getItem("persona"));
    console.log(this.auth);
    // this.getUnidadAcademica();
  },

    created(){
        this.$root.$refs.MenuExterno=this;
    },

	methods: {

		cancelPersona(){
			this.nuevaPersona = {};
			this.$refs['modalRegistro'].hide();
        },
        
        // pruebacomponente(){
        //     this.$root.$refs.VerProgramaPostgrado.prueba("sebas");
        // },

        /* pruebasofi(){
            console.log("holasofi");
        },
 */
        

        verificarCorreo() {
            this.ss.verificarCorreo(this.nuevaPersona.email).then(
                (result) => {
                    // console.log(result);
                    let response = result.data;
                    console.log(response);
                    
                    if(response.success){
                        // console.log("el correo no existe");
                        this.buscarSaga();
                    }else{
                        // console.log("el correo existe");
                        this.$bvToast.toast(
                            'El correo ya se encuentra registrado',
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Ocurrio un probela al verificar el correo',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        
		savePersona() {
            this.ss.storePersonaExterno(this.nuevaPersona).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
					)
					this.loginCredenciales.email = this.nuevaPersona.email;
					this.loginCredenciales.password = this.nuevaPersona.password;
					this.login();
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['modalRegistro'].hide();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
		},
		login() {
			this.ss.login(this.loginCredenciales)
				.then(
					(result) => {
						console.log(result);
						localStorage.setItem('persona', JSON.stringify(result.data.data));
						localStorage.setItem('token', JSON.stringify(result.data.token));
						console.log("logueado");
						this.login= true;
						this.$refs['modalLogin'].hide();
                        this.persona=result.data.data;
                        this.auth=result.data.data;
                        
                        this.loginCredenciales={};
						//this.$router.push('/');
						/*var persona = JSON.parse(localStorage.getItem('persona'));
						console.log(persona)*/
					}
				)
				.catch((error) => {
					this.errorBag = error.response.data.errors;
					this.$bvToast.toast(
						'Problema al Iniciar Sesion',
						{
							title: 'Error',
							variant: 'danger',
							autoHideDelay: 5000
						}
					)
				});
        },
        cerrarSesion() {
            localStorage.clear();
            this.auth={};
            this.$router.push("/inscribete");
            if (this.$msal.isAuthenticated()) {
                this.$msal.signOut();
            }
        },
		abrirModalLogin(){
			// this.tiponivelacademico = {};
			this.$refs['modalLogin'].show();
			
        },
        abrirModaReg(){
			this.$refs['modalRegistro'].show();
			
		},
		abrirModalRegistro(){
			// this.tiponivelacademico = {};
			this.$refs['modalLogin'].hide();
            this.$refs['modalRegistro'].show();
        },
        /* getUnidadAcademica() {
            this.ss.listExternoUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    // this.unidadAcademicas = response.data;
                    let uas=response.data
                    this.unidadAcademicas = uas.splice(1, 4);
                }
            ).catch(error => {
                console.log(error);
            });
        }, */

        buscarSaga() {
			let params = {
				CI: this.nuevaPersona.CI
			}
            this.ss.buscarSaga(params)
            .then(
                (result) => {
                    let response = result.data;
                    //Si encuentra al usuario...
                    if(response.success == true){
                        this.nuevaPersona.CodigoSaga = response.data.CodAlumno;
                        this.nuevaPersona.correoInstitucional = response.data.email_emi;
                        this.nuevaPersona.Celular = response.data.celular;
                        this.nuevaPersona.Telefono = response.data.Telefono;
                        this.nuevaPersona.Direccion = response.data.Direccion;
                    }
                    this.savePersona();
                    console.log(response);
                }
            )
            .catch(error => {
                console.log(error);
            });
		},
    },


};
</script>

<style scoped>
.nombreLogin{
    padding: 10px 0 10px 25px;
    color: white;
}

</style>