var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('v-app',{attrs:{"id":"inspire"}},[(
                _vm.paginasSimples.includes(this.$router.currentRoute.name) &&
                this.$router.currentRoute.name !== 'Login' &&
                this.$router.currentRoute.name !== 'CompletarPerfil'
            )?_c('menu-externo'):_vm._e(),(
                this.$router.currentRoute.name !== 'Login' &&
                !_vm.paginasSimples.includes(this.$router.currentRoute.name)
            )?_c('v-app-bar',{attrs:{"app":"","color":"#003f8a","dark":"","flat":""}},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){$event.preventDefault();return _vm.cerrarSesion.apply(null, arguments)}}},[_vm._v("mdi-logout")])],1)],1):_vm._e(),(
                this.$router.currentRoute.name !== 'Login' &&
                !_vm.paginasSimples.includes(this.$router.currentRoute.name)
            )?_c('v-navigation-drawer',{attrs:{"color":"white","permanent":_vm.$vuetify.breakpoint.mdAndUp,"app":"","flat":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-avatar',[_c('img',{attrs:{"src":_vm.auth.URLFoto}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.auth.Persona))]),_c('v-list-item-subtitle',[_vm._v("Sesión Iniciada")])],1)],1)]},proxy:true}],null,false,1296463557),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-divider'),_c('MenuVuetify')],1):_vm._e(),_c('v-main',[_c('router-view'),(
                    _vm.paginasSimples.includes(
                        this.$router.currentRoute.name
                    ) && this.$router.currentRoute.name !== 'Login'
                )?_c('footer-externo'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }