var axios = require('axios');

export default class PosgradoService {

    constructor() {
        
        axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE_REPOSITORIO_POSGRADO;
    }

    /* ----------------------------------------------------- Documentos de Posgrado*/
    showDocumento(id) {
        return axios.get('api/Documento/show?id=' + id);
    }

    indexDocumento() {
        return axios.defaults.baseURL + 'api/Documento/index';
    }

    listDocumento() {
        return axios.get('api/Documento/list');
    }

}