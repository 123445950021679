import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");
require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-responsive");

export default {
    name: "ReservaPage",
    data() {
        let ss = new MainService();
        
        return {
            msg: "ReservaPage",
            ss: ss,

            ajax: {
                "url": ss.indexReserva(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Persona', name: 'p.Persona', title: 'Persona' },
                { data: 'Libro', name: 'l.Libro', title: 'Libro' },
                //{ data: 'EstadoLibro', name: 'EstadoLibro', title: 'Estado' },
                //{ data: 'Estado', name: 'e.Estado', title: 'Estado Reserva' },
                { data: 'FechaPrestamo', name: 'r.FechaPrestamo', searchable: false , title: 'Fecha de Préstamo' },
                // { data: 'FechaDevolucion', name: 'r.FechaDevolucion', searchable: false, title: 'Fecha de Devolución' },
                { data: 'Observacion', name: 'r.Observacion', searchable: false, title: 'Observación' },
                { data: 'Reparticion', name: 're.Reparticion', title: 'Repartición' },
                { data: 'Tipo', name: 'r.Tipo', title: 'Tipo', render: function(data,type ,row)
                {
                    if ( row['Tipo'] == "Préstamo en sala" ) {
                        return ' <div style="color:purple;" > '+row.Tipo+' </div>'
                    }else if(row['Tipo'] == "Reserva exterior"){
                        return ' <div style="color:dodgerblue;" > '+row.Tipo+' </div>'
                    }

                   //return ' <div style="color:green;" > '+row.Estado+' </div>'
                } },
                {
                    data: 'estado2',
                    orderable: false,
                    title: 'Estado',
                    searchable: false
                },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
                {
                    data: 'libroDevuelto',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false,
                    visible: false
                },
            ],

            rutaApp: process.env.VUE_APP_MAIN_SERVICE,

            prueba: {},

            unidadacademicas: [],
            unidadacademica: {},

            reparticiones: [],
            reparticion: {},

            reservas: [],
            reserva: {},
            queryReserva: "",

            personas: [],
            persona: {},
            queryPersona: "",

            libros: [],
            libro: {},
            queryLibro: "",

            autors: [],
            autor: {},
            queryAutor: "",

            estados: [],
            estado: {},

            isLoading: false,
            errorBag: {}
        };
    },
    methods: {

    devolverLibro(data) {
            console.log("desde cambio estado",data);
            this.ss.cambioEstado({params:{'Reserva':data}}).then(
                (result) => {
                    //Antes de que se cree
                    let response = result.data;
                    console.log(response);
                    /* this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    ) */
                    this.$swal(
                        {
                                title: "Correcto",
                                text: response.msg,
                                icon: "success",
                                buttons: true
                        }
                    );
                    
                    this.$refs['datatable-reserva'].reloadAjax(this.ajax);
                    //this.$refs['frm-reserva'].hide();
                    this.$refs['frm-prestamo'].hide();
                }
            ).catch(error => {
                console.log(error);
               // this.isLoading=false;
            });

            //this.$refs['datatable-reserva'].reloadAjax(this.ajax);
            
    },
       
        /////////////////////////////////Filtro
    filtroReparticion(){
        
        let rep = this.reparticion.Reparticion;
        let est = this.estado.Estado;

        if(est == "Todos") est = null;

        this.ajax.data = function (d) {
             d.Reparticion = rep;
             d.Estado = est;

             console.log('reparticion',d.Reparticion);
             console.log('estado',d.Estado);
        }
        this.$refs['datatable-reserva'].reloadAjax(this.ajax);
        
    },

    //Generar Comprobante de Reserva
   generarReporte(){
        let id = this.reserva.id;
        let objeto = {
            Reserva: id
        };
        this.ss.generarReporte(objeto).then(
            (result) => {
                let response = result.data;
                var dir =this.rutaApp + response.url;
                window.open(dir);
                console.log(response);
            }
        ).catch(error => {
            console.log(error);
            this.isLoading = false;
        });
    },

    vistaReporte(){
        this.$refs['view-reporte'].show();
    },

    //////////////////////////////////////////////////// 
    newPrestamo() {
        this.reserva = {};
        this.$refs['frm-prestamo'].show();
    },
    newReserva() {
        this.reserva = {};
        this.$refs['frm-reserva'].show();

        //this.reserva.Tipo = "Prestamo";
    },
    showReserva(id) {
        this.isLoading = true;
        this.ss.showReserva(id).then(
            (result) => {
                let response = result.data;
                this.reserva = response.data;
                this.$refs['view-reserva'].show();
                this.isLoading = false;
            }
        ).catch(error => {
            console.log(error);
            this.isLoading = false;
        });
    },
    editReserva() {
        this.$refs['frm-reserva'].show();
        this.$refs['view-reserva'].hide();

        //this.reserva.Tipo = "Reserva";
    },
    
    cancelReserva() {
        if (this.reserva.id) {
            this.$refs['view-reserva'].show();
        }
        this.$refs['frm-reserva'].hide();
    },

    cancelPrestamo() {
        if (this.reserva.id) {
            this.$refs['view-reserva'].show();
        }
        this.$refs['frm-prestamo'].hide();
    },

    saveReserva() {
        //this.ss.storeReserva(this.reserva)
        this.reserva.ActualizarEstado=1;
        //console.log(this.reserva);
        this.ss.storeReserva(this.reserva).then(
            (result) => {
                console.log(result);
                //Antes de que se cree
                let response = result.data;
                // Después de que se cree
                this.reserva = response.data;
                this.$bvToast.toast(
                    response.msg,
                    {
                        title: 'Correcto',
                        variant: 'success',
                        autoHideDelay: 5000
                    }
                )
                //this.$refs['view-consulta'].show(); //para volver al modal
                console.log("reserva:",response);

                //this.enviarConfirmaciones();
                this.enviarConfirmaciones();
                
                this.$refs['frm-reserva'].hide();
                this.$refs['datatable-reserva'].reload();
            })
            .catch((error) => {
                this.errorBag = error.response.data.errors;
                this.$bvToast.toast(
                    'Problema al Guardar el Registro, favor verificar los Datos',
                    {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                )
            });
    },

    savePrestamo() {
        this.reserva.ActualizarEstado=1;
        this.ss.storePrestamo(this.reserva).then(
            (result) => {
                console.log(result);
                //Antes de que se cree
                let response = result.data;
                // Después de que se cree
                //this.reserva = response.data;
                this.$bvToast.toast(
                    response.msg,
                    {
                        title: 'Correcto',
                        variant: 'success',
                        autoHideDelay: 5000
                    }
                )
                //this.$refs['view-consulta'].show(); //para volver al modal
                console.log("reserva:",response);

                //this.enviarConfirmaciones();
                
                this.$refs['frm-prestamo'].hide();
                this.$refs['datatable-reserva'].reload();
            })
            .catch((error) => {
                this.errorBag = error.response.data.errors;
                this.$bvToast.toast(
                    'Problema al Guardar el Registro, favor verificar los Datos',
                    {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                )
            });
    },

    deleteReserva() {
        this.$swal({
            title: "Estás seguro que deseas eliminar?",
            text: "Esta acción es irreversible!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyReserva(this.reserva)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-reserva'].hide();
                                this.$refs['datatable-reserva'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
    },
        ////////////////////////////////// Enviar notificación del estudiante   al operador
    enviarNotificaciones() {
            console.log("enviar Notificación al operador!");
            this.ss.NotificacionOperador(this.reserva).then(
                (result) => {
                    console.log(result);
                    let response = result.data;

                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    ) 
                    this.$swal(
                    {
                                title: "Correcto",
                                text: response.msg,
                                icon: "success",
                                buttons: true,
                                dangerMode: true,
                    })

                    console.log(response);
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al enviar correo',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        })
                });
    },
        /////////////////////////////////////Enviar confirmacion al estudiante
    enviarConfirmaciones() {
            console.log("enviar observacion al estudiante!");
            this.ss.ConfirmacionEstudiante(this.reserva).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$swal(
                        {
                                    title: "Correcto",
                                    text: response.msg,
                                    icon: "success",
                                    buttons: true,
                                    dangerMode: true,
                        });
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    console.log(response);
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al enviar correo',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        })
                });
    },
    /////////////////////////////////////////////////////////////////////
    getEstado() {
            this.ss.listEstado().then(
                (result) => {
                    let response = result.data;
                    this.estados = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
    },
    getReparticion() {
            let params = {};
            params.UnidadAcademica = this.unidadacademica.UnidadAcademica;
            this.ss.listReparticion(params).then(
                (result) => {
                    let response = result.data;
                    this.reparticiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
    },
    getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadacademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
    },

    draw() {
        window.$('.btn-datatable-Reserva').on('click', (evt) => {
            const data = window.$(evt.target)[0].id;
            this.showReserva(data);
            });
 
            window.$('.btn-datatable-ReservaLibro').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.devolverLibro(data);
            });
    }
    },
    watch: {
        queryLibro(q) {
            this.ss.select2Libro(q).then((res) => {
                console.log(res);
                if (res.data.success) {
                    this.libros = res.data.items;
                } else {
                    this.libros = [];
                }
            })
        },
        queryPersona(q) {
            this.ss.select2Persona(q).then((res) => {
                console.log('hola',res);
                if (res.data.success) {
                    this.personas = res.data.items;
                } else {
                    this.personas = [];
                }
            })
        }
    },

    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {

            this.persona = persona;
            //this.getPersona();
            // this.getLibro();
            this.getEstado();
            this.getReparticion();
            this.getUnidadAcademica();
        }
    }
};
