<template>
    <v-list nav dense>
        
        <!-- <v-list-item-group> -->
        <v-list-group>
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon>mdi-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title> Mi organización </v-list-item-title>
                </v-list-item-content>
            </template>

            <!-- Persona -->
            <router-link
                class="black--text text--accent-4"
                to="Persona"
                active-class="blue--text text--accent-4"

                v-if="persona.Rol==1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-multiple</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Persona")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
        
            <!-- Unidad Académica -->
            <router-link
                class="black--text text--accent-4"
                to="UnidadAcademica"
                active-class="blue--text text--accent-4"

                v-if="persona.Rol<3"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-school</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.UnidadAcademica")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <!-- Rol -->
            <router-link
                class="black--text text--accent-4"
                to="Rol"
                active-class="blue--text text--accent-4"

                v-if="persona.Rol<3"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-key</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Rol")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <!-- <router-link
                class="black--text text--accent-4"
                to="EditUnidadAcademica"
                active-class="blue--text text--accent-4"

                v-if="persona.Rol!=3"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-sticker-emoji</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title> Registro </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- Perfil -->
            <router-link
                class="black--text text--accent-4"
                to="Profile"
                active-class="blue--text text--accent-4"

                v-if="persona.Rol<6"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-circle</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Profile")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

        </v-list-group>

        <v-list-group>
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon>mdi-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title> Libros </v-list-item-title>
                </v-list-item-content>
            </template>

             <!-- Libro -->
            <router-link
                class="black--text text--accent-4"
                to="Libro"
                active-class="blue--text text--accent-4"

                v-if="persona.Rol<6"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-book-open</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Libro")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <!-- Tipo de Libro -->
            <router-link
                class="black--text text--accent-4"
                to="TipoLibro"
                active-class="blue--text text--accent-4"

                v-if="persona.Rol<4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-book-open-variant</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.TipoLibro")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <!-- Categoría -->
            <router-link
                class="black--text text--accent-4"
                to="Categoria"
                active-class="blue--text text--accent-4"

                v-if="persona.Rol<4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-bookmark-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Categoria")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <!-- Origen -->
            <router-link
                class="black--text text--accent-4"
                to="Origen"
                active-class="blue--text text--accent-4"

                v-if="persona.Rol<4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-crosshairs</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Origen")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <!-- Editorial -->
            <router-link
                class="black--text text--accent-4"
                to="Editorial"
                active-class="blue--text text--accent-4"

                v-if="persona.Rol<4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-book-multiple</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Editorial")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <!-- Autor -->
            <router-link
                class="black--text text--accent-4"
                to="Autor"
                active-class="blue--text text--accent-4"

                v-if="persona.Rol<4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Autor")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <!-- Estado -->
            <router-link
                class="black--text text--accent-4"
                to="Estado"
                active-class="blue--text text--accent-4"

                v-if="persona.Rol<3"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-checkerboard</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Estado")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            
            <!-- <router-link
                class="black--text text--accent-4"
                to="PortadaLibro"
                active-class="blue--text text--accent-4"

                v-if="persona.Rol<4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-circle</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.PortadaLibro")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- Reparticion -->
            <router-link
                class="black--text text--accent-4"
                to="Reparticion"
                active-class="blue--text text--accent-4"

                v-if="persona.Rol<3"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-border-inside</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Reparticion")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <!-- LibroAutor -->
            <router-link
                class="black--text text--accent-4"
                to="LibroAutor"
                active-class="blue--text text--accent-4"

                v-if="persona.Rol<4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-file-account</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.LibroAutor")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            
            <!-- Clase -->
            <router-link
                class="black--text text--accent-4"
                to="Clase"
                active-class="blue--text text--accent-4"

                v-if="persona.Rol==1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-dns</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Clase")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="NivelAcademico"
                active-class="blue--text text--accent-4"

                v-if="persona.Rol==1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-dns</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.NivelAcademico")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                class="black--text text--accent-4"
                to="Especialidad"
                active-class="blue--text text--accent-4"

                v-if="persona.Rol==1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-dns</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Especialidad")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>


        </v-list-group>

       <!--  <v-list-group>
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon>mdi-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title> Reserva de Libros </v-list-item-title>
                </v-list-item-content>
            </template>
            <router-link
                class="black--text text--accent-4"
                to="Reserva"
                active-class="blue--text text--accent-4"

                v-if="persona.Rol<6"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-calendar-multiple-check</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Reserva")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
        </v-list-group>
 -->

        <v-list-group>
            <template v-slot:activator>
                <v-list-item-icon>
                    <v-icon>mdi-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title> Otros recursos </v-list-item-title>
                </v-list-item-content>
            </template>
            <router-link
                class="black--text text--accent-4"
                to="BibliotecaVirtual"
                active-class="blue--text text--accent-4"

                v-if="persona.Rol<6"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-airplay</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.BibliotecaVirtual")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

             <router-link
            class="black--text text--accent-4"
            to="RepoGrado"
            active-class="blue--text text--accent-4"
            v-if="persona.Rol<6"
            >
            <v-list-item>
                <v-list-item-icon>
                    <v-icon> mdi-checkbox-multiple-blank-outline </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{
                        $t("nav.RepoGrado")
                        }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </router-link>

        <router-link
            class="black--text text--accent-4"
            to="RepoPosgrado"
            active-class="blue--text text--accent-4"
            v-if="persona.Rol<6"
            >
            <v-list-item>
                <v-list-item-icon>
                    <v-icon> mdi-checkbox-multiple-blank </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{
                        $t("nav.RepoPosgrado")
                        }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </router-link>


           <!--  <router-link
                class="black--text text--accent-4"
                to="TrabajosGrado"
                active-class="blue--text text--accent-4"

                v-if="persona.Rol<6"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-note-multiple-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.TrabajosGrado")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <!-- <router-link
                class="black--text text--accent-4"
                to="TrabajosPosgrado"
                active-class="blue--text text--accent-4"

                v-if="persona.Rol<6"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-note-multiple</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.TrabajosPosgrado")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
 -->
            <!-- <router-link
                class="black--text text--accent-4"
                to="Investigaciones"
                active-class="blue--text text--accent-4"

                v-if="persona.Rol<6"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-book-open</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Investigaciones")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <router-link
                class="black--text text--accent-4"
                to="DiccionarioMilitar"
                active-class="blue--text text--accent-4"

                v-if="persona.Rol<6"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon> mdi-wan </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.DiccionarioMilitar")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
        </v-list-group>

        <router-link
            class="black--text text--accent-4"
            to="Usuarios"
            active-class="blue--text text--accent-4"
            v-if="persona.Rol<4"
            >
            <v-list-item>
                <v-list-item-icon>
                    <v-icon> mdi-account-multiple </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{
                        $t("nav.Usuarios")
                        }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </router-link>

        <router-link
            class="black--text text--accent-4"
            to="Reserva"
            active-class="blue--text text--accent-4"
            v-if="persona.Rol<6"
        >
            <v-list-item>
                <v-list-item-icon>
                    <v-icon>mdi-calendar-multiple-check</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>
                        Reservas 
                    </v-list-item-title>
                </v-list-item-content>
                </v-list-item>
        </router-link>

        <router-link
            class="black--text text--accent-4"
            to="Reporte"
            active-class="blue--text text--accent-4"
            v-if="persona.Rol<4"
        >
            <v-list-item>
                <v-list-item-icon>
                    <v-icon>mdi-page-layout-sidebar-right</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>
                        Reportes
                    </v-list-item-title>
                </v-list-item-content>
                </v-list-item>
        </router-link>

        <router-link
            class="black--text text--accent-4"
            to="Estadisticas"
            active-class="blue--text text--accent-4"
            v-if="persona.Rol<4"
        >
            <v-list-item>
                <v-list-item-icon>
                    <v-icon>mdi-chart-areaspline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>
                        Estadísticas
                    </v-list-item-title>
                </v-list-item-content>
                </v-list-item>
        </router-link>

        <router-link
            class= "btn btn-sm btn-warning"
            to="Buscador"
            active-class="yellow"
            v-if="persona.Rol<6"
            >
            <v-list-item>
                <v-list-item-icon>
                    <v-icon> mdi-home-variant </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{
                        $t("nav.Buscador")
                        }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </router-link>
        <!-- </v-list-item-group> -->

    </v-list>
</template>

<script>
export default {
    name: "MenuVuetify",
    data() {
        return {
            msg: "MenuVuetify",
            persona: {}
        };
    },

    mounted() {
        this.persona = JSON.parse(localStorage.getItem('persona'));
    } 
};
</script>