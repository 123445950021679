import MainService from "@/services/MainService.js";
import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios';
import msal from 'vue-msal'
//import Loader from "@/components/Loader";
import dataTable from "@/components/Datatable";
window.$ = window.jQuery = require("jquery");

import MenuExterno from "@/components/MenuExterno";
import FooterExterno from "@/components/FooterExterno"; 

//import pagination from 'vue-bootstrap-pagination'

Vue.component('pagination', require('laravel-vue-pagination'));
Vue.use(VueAxios, axios)
Vue.use(msal, {
	auth: {
		clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
		tenantId: process.env.VUE_APP_MSAL_TENANT_ID,
		redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI
	}
});

export default {
	name: 'BuscadorPage',
	data() {
        
		let ss = new MainService();
		return {
			ss: ss,
            rutaPortada:process.env.VUE_APP_MAIN_SERVICE,

			ajax: {
                "url": ss.indexLibro(),
                headers: ss.getToken(),
            },

            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'URLPortada', searchable: false ,name: 'URLPortada', title: 'Portada', render: function(data,type ,row)
                { return '  <img src="'+row.URLPortada+'" style="width: 70%" />'} },
               /*  { data: 'Portada', name: 'Portada', title: 'Portada', render: function(data,type ,row){
                return ' <img src="'+process.env.VUE_APP_MAIN_SERVICE+'storage/documents/'+row.Portada+'" style="width: 70%" />'
                } }, */
                { data: 'Libro', name: 'l.Libro', title: 'Libro / Autor' },
                // { data: 'Codigo', name: 'l.Codigo', title: 'Código' },
                // { data: 'Autor', name: 'a.Autor', title: 'Autor' },
                // { data: 'Editorial', name: 'e.Editorial', title: 'Editorial' },
                // { data: 'Categoria', name: 'c.Categoria', title: 'Categoría' },
                { data: 'Reparticion', name: 'r.Reparticion', title: 'Repartición' },
                // { data: 'TipoLibro', name: 'tl.TipoLibro', title: 'Tipo' },
                { data: 'Estado', name: 'l.Estado', title: 'Estado', render: function(data,type ,row)
                {
                    if ( row['Estado'] == "Disponible" ) {
                        return ' <div style="color:green;" > '+row.Estado+' </div>'
                    }else if(row['Estado'] == "Ocupado"){
                        return ' <div style="color:red;" > '+row.Estado+' </div>'
                    }

                   //return ' <div style="color:green;" > '+row.Estado+' </div>'
                }},
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false

                   
                },
                {
                    data: 'reserva',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                } 
            ],

            persona:{},
            auth:{},

            unidadacademicas: [],
            unidadacademica: {},

            reservas: [],
            reserva: {},

            tiplibros: [],
            tipolibro: {},

			libros: [],
            libro: {},
			q: "",

			autores: [],
            autor: {},
			qa: "",

			categorias: [],
            categoria: {},
			qc: "",

			reparticiones: [],
            reparticion: {},
			qr: "",
            qAutor:"",

            nrLibros: 10,

            qLibro:"",

            queryLibro:"",

            filtros:{
                ua: "",
                rep:"",
                lib:"",
                cat:"",
                aut:""
            },

            estados: [],
            estado: {},

		}

	},

    components: {
	dataTable,
	MenuExterno,
	FooterExterno
    },
	props: {
	msg: String
	},

	methods: {
        /////////////////////////////////////////////////// Reserva de Libro
        /* newReserva(libro) {
            console.log(libro);
            this.ss.storeReserva({'Libro':parseInt(libro)}).then(
                (result) => {
                    //Antes de que se cree
                    let response = result.data;
                     // Después de que se cree
                     this.reserva = response.data;
                    console.log(response);
                   this.$swal(
                    {
                            title: "Correcto",
                            text: response.msg,
                            icon: "success",
                            buttons: true
                    }
                );
                    
                   this.enviarNotificaciones();
                }
            ).catch(error => {
                console.log(error);
            });
            //console.log(this.reserva);
            this.verificarDatosCompletos();
        }, */

        filtro(){
           
            let estadoLibro = document.getElementById('EstadoLibro').value;
            let rep = this.reparticion.Reparticion;
            let cat = this.categoria.Categoria;

            if(estadoLibro == "Todos") estadoLibro = null;

            this.ajax.data = function (d) {
                 d.Estado = estadoLibro;
                 d.Reparticion = rep;
                 d.Categoria = cat;

                 console.log(d.Estado);
                 console.log('reparticion',d.Reparticion);
            }
            this.$refs['datatable-libro'].reloadAjax(this.ajax);
        },

        newReserva(data) {
            //this.isLoading=true;
            //console.log("desde new reserva",data);

            console.log(this.libro.Estado);

            if(this.libro.Estado == "Disponible"){
                console.log(this.libro.Estado);

                this.ss.storeReserva({'Libro': data, 'Estado':2}).then(
                    (result) => {
                        //Antes de que se cree
                        let response = result.data;
                        // Después de que se cree
                        this.reserva = response.data;
                        console.log(response);
                        this.$swal(
                            {
                                    title: "Correcto",
                                    text: response.msg,
                                    icon: "success",
                                    buttons: true
                            }
                        );
                        
                        this.enviarNotificaciones();
                    }
                ).catch(error => {
                    console.log(error);
                   // this.isLoading=false;
                });
                this.verificarDatosCompletos();
            }
            else{
                this.$swal(
                    {
                            title: "Incorrecto",
                            text: "¡Este libro ya está ocupado!",
                            icon: "warning",
                            buttons: true
                    }
                );
            }
        },

        showLibroReserva(id) {
            this.isLoading=true;
            this.ss.showLibro(id).then(
                (result) => {
                    let response = result.data;
                    this.libro = response.data;
                    //this.$refs['view-libro'].show();
                    this.newReserva(this.libro.id);
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        showLibro(id) {
            this.isLoading=true;
            this.ss.showLibro(id).then(
                (result) => {
                    let response = result.data;
                    this.libro = response.data;
                    this.$refs['view-libro'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        //#region  - Enviar notificación al Operador
        enviarNotificaciones() {
            console.log("enviar Notificación al operador!");
            this.ss.NotificacionOperador(this.reserva).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$swal(
                    {
                                title: "Correcto",
                                text: response.msg,
                                icon: "success",
                                buttons: true,
                                dangerMode: true,
                    })
                   
                    console.log(response);
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al enviar correo',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        })
                });
        },

        //#endregion
		
        //#region - Métodos de Búsqueda 

		/* queryTitle(){
            this.filtros.cat = "";
            this.filtros.aut = "";
            this.nrLibros = 1;
            console.log(this.filtros);
			this.ss.listLibro(this.filtros).then((res) => {
                console.log(res);
                if(res.data.success){
                this.libros = res.data.data;
					console.log(this.libros);
                    this.libros = this.libros.slice(0, this.nrLibros);

                }else{
                    this.libros = [];
                }

              })
		},
		queryAutor(){
			this.filtros.cat = "";
            this.filtros.lib = "";
            this.nrLibros = 1;
            console.log(this.filtros);
			this.ss.listLibro(this.filtros).then((res) => {
                console.log(res);
                if(res.data.success){
                this.libros = res.data.data;
					console.log(this.libros);
                    this.libros = this.libros.slice(0, this.nrLibros);
                }else{
                    this.libros = [];
                }

              })
		},
		queryCategory(){
            this.filtros.aut = "";
            this.filtros.lib = "";
            this.nrLibros = 1;
            console.log(this.filtros);
			this.ss.listLibro(this.filtros).then((res) => {
                console.log(res);
                if(res.data.success){
                    this.libros = res.data.data;
					console.log(this.libros);
                    this.libros = this.libros.slice(0, this.nrLibros);
                }else{
                    this.libros = [];
                }
            })
		},
		queryUbicacion(){
            this.filtros.aut = "";
            this.filtros.lib = "";
            this.filtros.cat = "";
            this.nrLibros = 1;
            console.log(this.filtros);
			this.ss.listLibro(this.filtros).then((res) => {
                console.log(res);
                if(res.data.success){
                    this.libros = res.data.data;
					console.log(this.libros);
                    this.libros = this.libros.slice(0, this.nrLibros);
                }else{
                    this.libros = [];
                }
              })
		},
		queryVacio(){
            this.filtros.lib = "";
            this.filtros.aut = "";
            this.filtros.cat = "";
            this.filtros.rep = "";
            this.unidadacademica.UnidadAcademica = "";

			this.queryTitle();
            this.queryAutor();
            this.queryCategory();
            this.queryUbicacion();
		},
 */
        //#endregion

        //#region  - Paginador 
        /* verMasLibros(){
            if(this.filtros.rep && this.filtros.ua){
                this.queryTitle();
                this.queryAutor();
                this.queryCategory();
                this.queryUbicacion();
                this.nrLibros = this.nrLibros + 1;
            }

           this.nrLibros = this.nrLibros + 10;
            this.getLibro(); 
        },
        verMenosLibros(){
            if(this.filtros.rep && this.filtros.ua){
                this.queryTitle();
                this.queryAutor();
                this.queryCategory();
                this.queryUbicacion();
                this.nrLibros = this.nrLibros - 1;
            }

            this.nrLibros = this.nrLibros - 10;
            this.getLibro();
        }, */
        //#endregion

        //#region - Obtener datos
        getLibro() {
            this.ss.listLibro().then(
                (result) => {
                    let response = result.data;
                    this.libros = response.data;

                    this.libros = this.libros.slice(0, this.nrLibros);
                }
            ).catch(error => {
                console.log(error);
            });
        },
		getAutor() {
            this.ss.listAutor().then(
                (result) => {
                    let response = result.data;
                    this.autores = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
		getCategoria() {
            this.ss.listCategoria().then(
                (result) => {
                    let response = result.data;
                    this.categorias = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getReparticion() {
            let params = {};
            params.UnidadAcademica = this.unidadacademica.UnidadAcademica;
            console.log(params);
            this.ss.listReparticion(params).then(
                (result) => {
                    let response = result.data;
                    this.reparticiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadacademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEstado() {
            this.ss.listEstado().then(
                (result) => {
                    let response = result.data;
                    this.estados = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
       /*  getTipoLibro() {
            this.ss.listTipoLibro().then(
                (result) => {
                    let response = result.data;
                    this.tipolibros = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        }, */
        //#endregion

        //#region - Verificar datos antes de loguearse
        verificarDatosCompletos(){
			this.auth = JSON.parse(localStorage.getItem("persona"))
			if(this.auth == null || this.auth == ""){
				// this.$swal({
				// 	title: "Usted no Inició Sesion",
				// 	text: "Por favor logueate, Luego podras inscribirte",
				// 	icon: "warning",
				// 	buttons: true,
                //     dangerMode: true,});
                    this.$swal({
                        title: "No iniciaste sesión.",
                        text: "¡Por favor primero logueate!",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    }).then(function() {
                        //window.location = "Login";
                        this.$router.push("Libro");
                        //console.log('Hola!');
                    });
					return;
			}
		},
        //#endregion

        draw() {
            window.$('.btn-datatable-Libro').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showLibro(data);
            });
            
            window.$('.btn-datatable-ReservaLibro').on('click', (evt) => {
                let data = window.$(evt.target)[0].id;
                console.log("este es el data de new reserva",data);
                this.showLibroReserva(data);

                //this.newReserva(data);
            });
        }

	},
    watch: {
        queryLibro(q) {
            console.log(q);

            this.ss.select2Libro(q).then((res) => {
                console.log('hola',res);
                //console.log(q);
                if (res.data.success) {
                    this.libros = res.data.items;

                    this.ajax.data = function (d) {
                        d.Libro = q;
                   }
                   this.$refs['datatable-libro'].reloadAjax(this.ajax);
                   
                } else {
                    this.libros = [];
                }
            })
        }
    },
    computed: {
        isActived: function() {
            return this.pagination.current_page;
        },
    },
	mounted() {
        this.persona = JSON.parse(localStorage.getItem("persona"));
		this.auth = JSON.parse(localStorage.getItem("persona"));

		window.scrollTo(0, 0);

		this.getLibro();
		this.getAutor();
		this.getCategoria();
		this.getReparticion();
        this.getUnidadAcademica();
		this.getEstado();
        //this.getTipoLibro();

		/* if (!this.auth) {
            //this.$refs['info'].show();
        } else{
			this.getLibro();
		} */
	}
} 

