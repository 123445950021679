import MainService from "@/services/MainService.js";
import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios';
import msal from 'vue-msal'

//import Loader from "@/components/Loader";

//import dataTable from "@/components/Datatable";
window.$ = window.jQuery = require("jquery");

import MenuExterno from "@/components/MenuExterno";
import FooterExterno from "@/components/FooterExterno";

Vue.use(VueAxios, axios)

Vue.use(msal, {
	auth: {
		clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
		tenantId: process.env.VUE_APP_MSAL_TENANT_ID,
		redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI
	}
});

export default {
	name: 'BuscadorBibliotecaVirtualPage',

	data() {
		let ss = new MainService();
		return {
			//msg: "LibroPage",
			ss: ss,
			ajax: {
                "url": ss.indexBibliotecaVirtual(),
                headers: ss.getToken(),
            },

			rutaApp:process.env.VUE_APP_MAIN_SERVICE,

			bibliotecasvirtuales: [],
            bibliotecavirtual: {},

			unidadacademicas: [],
            unidadacademica: {},

		};

	},


    components: {
	// dataTable,
	MenuExterno,
	FooterExterno,
    },
	props: {
	msg: String
	},

	methods: {

		getBibliotecaVirtual() {
            this.ss.listBibliotecaVirtual().then(
                (result) => {
                    let response = result.data;
                    this.bibliotecasvirtuales = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

		getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadacademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

		descargarGuia(guia){
			console.log(guia);
			var dir =this.rutaApp + 'documents/'+ guia;
			console.log(dir);
			window.open(dir);
		},
		


	},
	mounted() {
		this.auth = JSON.parse(localStorage.getItem("persona"));
		window.scrollTo(0, 0);

		this.getBibliotecaVirtual();
		this.getUnidadAcademica();
		/* this.descargarGuiaDescarga();
		this.descargarGuiaAcceso(); */

		/* if (!this.auth) {
            //this.$refs['info'].show();
        } else{
			this.getLibro();
		} */
	}
} 

