import { render, staticRenderFns } from "./CompletarPerfilPage.vue?vue&type=template&id=2de09a82&scoped=true&"
import script from "./CompletarPerfilPage.js?vue&type=script&lang=js&"
export * from "./CompletarPerfilPage.js?vue&type=script&lang=js&"
import style0 from "./CompletarPerfilPage.vue?vue&type=style&index=0&id=2de09a82&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2de09a82",
  null
  
)

export default component.exports