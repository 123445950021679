import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "PortadaLibroPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PortadaLibroPage",
            ss: ss,
            ajax: {
                "url": ss.indexLibro(),
                headers: ss.getToken(),
            },
            auth:{},
            persona: {},

            libros: [],
            libro: {},

            descripcion: [],
            descripciones: {},

            anos: [],
            ano: {},

            categorias:[],
            categoria: {},

            editoriales:[],
            editorial: {},

            reparticiones:[],
            reparticion: {},

            autores:[],
            autor: {},

            origenes:[],
            origen: {},

            tipolibros:[],
            tipolibro: {},


            isLoading: false,
            isLoadingFile:false,
            errorBag: {},
            password: {}
        };
    },
    methods: {

        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            );
                            this.libro.Portada = result.data.data;
                            this.saveLibro();
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        
        
        getLibro() {
            this.ss.listLibro().then(
                (result) => {
                    let response = result.data;
                    this.libros = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

       
        getReparticion() {
            this.ss.listReparticion().then(
                (result) => {
                    let response = result.data;
                    this.reparticiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        saveLibro() {
            this.ss.storeLibro(this.persona)
                .then(result => {
                    let response = result.data;
                    this.persona = response.data;

                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                })
                .catch(error => {
                    console.log(error);
                    this.$bvToast.toast(
                        'Error al guardar el registro',
                        {
                            title: 'Oops!',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.errorBag = error.data.errors;
                });
        }

    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        this.auth = JSON.parse(localStorage.getItem('persona'));
        console.log(this.auth);
        if (!this.auth) {
            this.$router.push('/Login');
        } else {

            this.getLibro();
            this.getReparticion();
        }
    }
};
